import React from "react";
import HeroSection from "../components/Hero-Section/HeroSection";
// import CompanySection from '../components/Company-Section/Company';
import AboutUs from "../components/About-Us/AboutUs";
// import School from '../components/School/School';
import ChooseUs from "../components/Choose-Us/ChooseUs";
import Features from "../components/Feature-Section/Features";
// import FreeCourse from '../components/Free-Course-Section/FreeCourse';
import SliderFeatures from '../components/Feature-Section/Slider/Slider-Features';
import Newsletter from "../components/Newsletter/Newsletter";

const Home = () => {
  return (
    <>
      <HeroSection />

      {/* <CompanySection /> */}

      <AboutUs />

      {/* <School /> */}

      <ChooseUs />
      <Features />

      {/* <FreeCourse /> */}

      <SliderFeatures />

      <Newsletter />
    </>
  );
};

export default Home;
