import React from "react";
import School from "../../components/Benefits/School";
import Parents from "../../components/Benefits/Parents";
import Teachers from "../../components/Benefits/Teachers";
import Students from "../../components/Benefits/Students";
import { Container, Row, Col } from "reactstrap";
// import Card from "react-bootstrap/Card";

const Home = () => {
  return (
    <section>
      <Container className="_benefit">
        <Row className="mt-10">
          <h1 className="tw-text-center">Benefits of Brainpiece</h1>
          <Col className="col-md-6 mt-3">
            <School />
          </Col>
          <Col className="col-md-6 mt-3">
            <Parents />
          </Col>
        {/* </Row>
        <Row className="mt-2"> */}
          <Col className="col-md-6 mt-3">
            <Teachers />
          </Col>
          <Col className="col-md-6 mt-3">
            <Students />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Home;
