import React from "react";
import {api} from "../../http";

import {
  Column,
  Text,
  Button,
  Input,
  TextArea,
  MColumn,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { useState } from "react";

// import { useForm } from "react-hook-form";

const SupportenquiryPage = () => {
  // const { handleSubmit, formState } = useForm();

  // const { isSubmitting } = formState;

  // function submitForm(data) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve();
  //     }, 4000);
  //   });
  // }

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  async function SendInquiry() {
    setLoader(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("message", message);

    let res = await fetch(api + "/api/inquiry", {
      method: "post",
      body: formData,
    });

    res = await res.json();
    setLoader(false);
    res.errors && setErrors(res.errors);
    console.warn(res);
    !res.errors && navigate("/supportenquirysent");
  }

  const navigate = useNavigate();

  function handleNavigate13() {
    navigate("/support");
  }

  return (
    <>
      <Layout>
        {/* <Column className="bg-white_A700 tw-font-notosans tw-items-center tw-justify-start mx-[auto] tw-w-[100%]"> */}
        <Column className="tw-flex tw-flex-col tw-items-center tw-justify-start lg:tw-mt-[25px] xl:tw-mt-[32px] 2xl:tw-mt-[36px] 3xl:tw-mt-[43px] tw-w-[100%]">
          <Column className="tw-font-opensans tw-items-center tw-justify-start tw-pt-6 tw-pb-8 tw-space-y-4">
            <Text className="heading" as="h1" variant="h1">
              Support
            </Text>
            <Text
              className="headingdesc tw-text-center"
              variant="body13"
              as="h1"
            >
              We would love to hear from you
            </Text>
            <Button
              className="tw-common-pointer tw-font-medium 3xl:tw-mt-[111px] lg:tw-mt-[66px] xl:tw-mt-[82px] 2xl:tw-mt-[93px] lg:tw-text-[13px] xl:tw-text-[16px] 2xl:tw-text-[18px] 3xl:tw-text-[22px] tw-text-center tw-tracking-ls1"
              onClick={handleNavigate13}
              shape="RoundedBorder6"
              size="md"
            >
              View Contact Information
            </Button>
          </Column>
          <Column className="tw-items-center tw-justify-start lg:tw-mt-[45px] xl:tw-mt-[56px] 2xl:tw-mt-[64px] 3xl:tw-mt-[76px] tw-w-[64%]">
            <MColumn className="tw-bg-deep_purple_A200 tw-justify-center tw-p-5 lg:tw-p-[43px] xl:tw-p-[54px] 2xl:tw-p-[61px] 3xl:tw-p-[73px] tw-w-[100%] tw-space-y-6 lg:tw-space-y-0">
              <Column className="lg:tw-ml-[10px] xl:tw-ml-[13px] 2xl:tw-ml-[15px] 3xl:tw-ml-[18px] tw-my-[1px] lg:tw-w-[44%]">
                <Text className="tw-font-inter tw-font-normal tw-not-italic tw-p-[0] lg:tw-text-[11px] xl:tw-text-[14px] 2xl:tw-text-[16px] 3xl:tw-text-[20px] placeholder:tw-text-white_A700_90 tw-text-white_A700_90 tw-w-[100%]">
                  Working hours
                </Text>
                <hr className="tw-my-2 lg:tw-my-5 tw-text-secondary-900 lg:tw-text-white" />
                <Text
                  className="tw-font-sen lg:tw-mt-[18px] xl:tw-mt-[23px] 2xl:tw-mt-[26px] 3xl:tw-mt-[31px] tw-text-white_A700 tw-w-[auto]"
                  as="h6"
                  variant="h6"
                >
                  Monday To Friday
                </Text>
                <Text
                  className="tw-font-sen xl:tw-mt-[10px] 2xl:tw-mt-[12px] 3xl:tw-mt-[14px] lg:tw-mt-[8px] tw-text-white_A700 tw-w-[auto]"
                  as="h6"
                  variant="h6"
                >
                  9:00 AM to 8:00 PM{" "}
                </Text>
                <Text
                  className="tw-font-inter tw-font-normal lg:tw-mt-[11px] xl:tw-mt-[14px] 2xl:tw-mt-[16px] 3xl:tw-mt-[19px] tw-not-italic tw-text-white_A700_90 tw-w-[auto]"
                  variant="body13"
                >
                  Our Support Team is available 24/7
                </Text>
              </Column>

              <hr className="tw-my-5 tw-text-secondary-800 lg:tw-hidden" />

              <Column className="lg:tw-ml-[35px] xl:tw-ml-[44px] 2xl:tw-ml-[50px] 3xl:tw-ml-[60px] lg:tw-w-[44%]">
                <Text className="tw-font-inter tw-font-normal tw-not-italic tw-p-[0] lg:tw-text-[11px] xl:tw-text-[14px] 2xl:tw-text-[16px] 3xl:tw-text-[20px] placeholder:tw-text-white_A700_90 tw-text-white_A700_90 tw-w-[100%]">
                  Contact Us
                </Text>
                <hr className="tw-my-2 lg:tw-my-5 tw-text-secondary-900 lg:tw-text-white" />
                <Text
                  className="tw-font-sen lg:tw-mt-[17px] xl:tw-mt-[22px] 2xl:tw-mt-[25px] 3xl:tw-mt-[30px] tw-text-white_A700 tw-w-[auto]"
                  as="h6"
                  variant="h6"
                >
                  +234-812-4619-885
                </Text>
                <Text className="tw-font-inter tw-font-normal tw-ml-[1px] lg:tw-mt-[11px] xl:tw-mt-[14px] 2xl:tw-mt-[16px] 3xl:tw-mt-[19px] tw-not-italic lg:tw-text-[13px] xl:tw-text-[17px] 2xl:tw-text-[19px] 3xl:tw-text-[23px] tw-text-white_A700_90 tw-w-[auto]">
                  info@brainpiece.net
                </Text>
              </Column>
            </MColumn>
            <Column className="tw-items-center tw-justify-start lg:tw-mt-[27px] xl:tw-mt-[33px] 2xl:tw-mt-[38px] 3xl:tw-mt-[45px] tw-w-[100%]">
              {/* <form onSubmit={handleSubmit(submitForm)}> */}
                <Input
                  className="tw-font-inter tw-font-normal tw-not-italic tw-p-[0] lg:tw-text-[13px] xl:tw-text-[17px] 2xl:tw-text-[19px] 3xl:tw-text-[23px] placeholder:tw-text-bluegray_901 tw-text-bluegray_901 tw-w-[100%]"
                  wrapClassName="tw-w-[100%]"
                  type="tw-text"
                  name="FrameOne"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full Name"
                  shape="RoundedBorder2"
                  variant="OutlineGray6007f"
                ></Input>
                <span className="text-danger">{errors && errors.name}</span>
                <Input
                  className="tw-font-inter tw-font-normal tw-not-italic tw-p-[0] lg:tw-text-[13px] xl:tw-text-[17px] 2xl:tw-text-[19px] 3xl:tw-text-[23px] placeholder:tw-text-bluegray_901 tw-text-bluegray_901 tw-w-[100%]"
                  wrapClassName="2xl:tw-mt-[18px] 3xl:tw-mt-[21px] lg:tw-mt-[12px] tw-w-[100%] xl:tw-mt-[16px]"
                  type="tw-text"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Your Phone Number"
                  shape="RoundedBorder2"
                  variant="OutlineGray6007f"
                ></Input>
                <span className="text-danger">{errors && errors.phone}</span>
                <Input
                  className="tw-font-inter tw-font-normal tw-not-italic tw-p-[0] lg:tw-text-[13px] xl:tw-text-[17px] 2xl:tw-text-[19px] 3xl:tw-text-[23px] placeholder:tw-text-bluegray_901 tw-text-bluegray_901 tw-w-[100%]"
                  wrapClassName="2xl:tw-mt-[18px] 3xl:tw-mt-[21px] lg:tw-mt-[12px] tw-w-[100%] xl:tw-mt-[16px]"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  name="FrameOne One"
                  placeholder="Your Email"
                  shape="RoundedBorder2"
                  variant="OutlineGray6007f"
                ></Input>
                <span className="text-danger">{errors && errors.Email}</span>
                <TextArea
                  className="tw-font-inter tw-font-normal lg:tw-mt-[12px] xl:tw-mt-[16px] 2xl:tw-mt-[18px] 3xl:tw-mt-[21px] tw-not-italic lg:tw-text-[13px] xl:tw-text-[17px] 2xl:tw-text-[19px] 3xl:tw-text-[23px] placeholder:tw-text-bluegray_901 tw-text-bluegray_901 tw-w-[100%]"
                  name="FrameOne Two"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                ></TextArea>
                <span className="text-danger">{errors && errors.message}</span>

                <Button
                  className="tw-common-pointer tw-font-bold tw-font-sen lg:tw-mt-[13px] xl:tw-mt-[16px] 2xl:tw-mt-[19px] 3xl:tw-mt-[22px] lg:tw-text-[20px] xl:tw-text-[25px] 2xl:tw-text-[28px] 3xl:tw-text-[34px] tw-text-center tw-w-[100%]"
                  onClick={SendInquiry}
                  size="lg"
                  disabled={loader}
                  // disabled={isSubmitting}
                >
                  {loader && (
                    <span className="spinner-grow spinner-grow-sm"></span>
                  )}
                  Send Message
                </Button>
              {/* </form> */}
            </Column>
          </Column>
        </Column>
        {/* </Column> */}
      </Layout>
    </>
  );
};

export default SupportenquiryPage;
