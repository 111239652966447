import React from "react";

import { Column, Text, Button, List, Img, MRow } from "../../components";
// import Header from "components/Header/Header";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";
// import { Link } from "react-router-dom";

// import { Container, Row, Col } from "reactstrap";

const SupportPage = () => {
  const navigate = useNavigate();

  function handleNavigate6() {
    navigate("/supportenquiry");
  }

  return (
    <>
      {/* <Column className="bg-white_A700 tw-font-notosans tw-items-center tw-justify-start mx-[auto] tw-w-[100%]">
      </Column> */}
      <Layout>
        <Column className="tw-flex tw-flex-col tw-items-center tw-justify-start lg:tw-mt-[39px] xl:tw-mt-[48px] 2xl:tw-mt-[55px] 3xl:tw-mt-[66px] tw-w-[100%] tw-mb-8">
        {/* <Column className="tw-flex tw-flex-col tw-items-center"> */}
          <Column className="tw-font-opensans tw-items-center tw-justify-start tw-pt-6 tw-pb-8 tw-space-y-4">
            <Text className="heading" as="h1" variant="h1">
              Support
            </Text>
            <Text
              className="headingdesc tw-text-center"
              variant="body13"
              as="h1"
            >
              We would love to hear from you
            </Text>
            {/* <br></br> */}
            <Button
              className="tw-common-pointer tw-font-medium 3xl:tw-mt-[111px] lg:tw-mt-[66px] xl:tw-mt-[82px] 2xl:tw-mt-[93px] lg:tw-text-[13px] xl:tw-text-[16px] 2xl:tw-text-[18px] 3xl:tw-text-[22px] tw-text-center tw-tracking-ls1"
              onClick={handleNavigate6}
              shape="RoundedBorder6"
              size="md"
            >
              Make an enquiry
            </Button>
          </Column>
          <List
            className="lg:tw-gap-[26px] xl:tw-gap-[32px] 2xl:tw-gap-[36px] 3xl:tw-gap-[44px] tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-min-h-[auto] lg:tw-mt-[41px] xl:tw-mt-[52px] 2xl:tw-mt-[59px] 3xl:tw-mt-[70px] tw-w-[82%] tw-gap-y-8"
            orientation="horizontal"
          >
            {card(
              "Email",
              "info@brainpiece.net",
              "images/img_mail.svg",
              "CONTACT",
              "mail:info@brainpiece.net"
            )}
            {card(
              "Phone",
              "+234-812-4619-885",
              "images/img_call.svg",
              "CALL",
              "tel:+2348124619885"
            )}
            {card(
              "Office",
              "2, Sunday Street, Palm Groove, Lagos, Nigeria",
              "images/img_location.svg",
              "LOCATION"
            )}
          </List>
        </Column>
      </Layout>
    </>
  );
};

export default SupportPage;

function card(title, desc, img, btnText, btnLink = "#") {
  return (
    <Column className="tw-border-bw092 tw-border-deep_purple_A100 tw-border-solid tw-justify-end tw-p-[19px] xl:tw-p-[24px] 2xl:tw-p-[27px] 3xl:tw-p-[32px] tw-rounded-radius2214 tw-w-[100%]">
      <Img src={img} className="tw-w-[20px]" alt="mail" />
      <MRow className="tw-justify-start ml-[1px] lg:tw-mt-[15px] xl:tw-mt-[19px] 2xl:tw-mt-[22px] 3xl:tw-mt-[26px] lg:tw-pt-[4px] xl:tw-pt-[5px] 2xl:tw-pt-[6px] 3xl:tw-pt-[7px]">
        <Column className="tw-justify-start tw-w-[100%]">
          <Text
            className="tw-not-italic tw-text-bluegray_902 tw-tracking-ls1 tw-w-[auto]"
            variant="body17"
          >
            {title}
          </Text>
          <Text
            className="tw-font-medium xl:tw-mt-[11px] 2xl:tw-mt-[13px] 3xl:tw-mt-[15px] lg:tw-mt-[9px] xl:tw-text-[11px] 2xl:tw-text-[12px] 3xl:tw-text-[15px] lg:tw-text-[9px] tw-text-bluegray_800 tw-underline tw-w-[auto]"
            variant="body22"
          >
            {desc}
          </Text>
        </Column>
        <a href={btnLink}>
          <Button
            className="tw-font-semibold italic lg:tw-mt-[18px] tw-h-[25px] xl:tw-mt-[23px] 2xl:tw-mt-[26px] 3xl:tw-mt-[31px] tw-text-[7px] lg:tw-text-[9px] tw-text-center lg:tw-w-[40%]"
            shape="RoundedBorder14"
          >
            {btnText}
          </Button>
        </a>
      </MRow>
    </Column>
  );
}
