import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const FeatureCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {title, desc, icon} = props.feature;
  return (
    <div className="App">
      <motion.div
        transition={{ layout: { duration: 1, type: "spring" } }}
        layout
        onMouseOver={() => setIsOpen(!isOpen)}
        className="card pink-bg"
        style={{
          borderRadius: "1rem",
        }}
      >
        <motion.h6 layout="position">{title}</motion.h6>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              exit={{ opacity: 0 }}
              className="expand"
            >
              <p>
                {desc}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default FeatureCard;
