import React from "react";
import CountUp from "react-countup";
import { Row } from "reactstrap";

const Text = () => {
  return (
    <div className="about__content">
      <h2 className="d-none d-lg-block">About Us</h2>
      <p>
        Our School Management System is a web based educational management and
        information system which provides a suite of software covering all part
        of school management. It is designed for efficient and effective
        management of educational institutions built for school administration
        where parents can conveniently monitor the performances of their
        Wards/Children anywhere they are.
        {/* <a href="">Learn more</a> */}
      </p>

      {/* <div className="about__counter">
        <Row xs="4">
          <div className="single__counter">
            <span className="counter">
              <CountUp start={0} end={2} duration={2} suffix="" />
            </span>
            <p className="counter__title">Schools</p>
          </div>
          <div className="single__counter">
            <span className="counter">
              <CountUp start={0} end={38} duration={2} suffix="" />
            </span>
            <p className="counter__title">Teachers</p>
          </div>
          <div className="single__counter">
            <span className="counter">
              <CountUp start={0} end={275} duration={2} suffix="" />
            </span>
            <p className="counter__title">Students</p>
          </div>
          <div className="single__counter">
            <span className="counter">
              <CountUp start={0} end={209} duration={2} suffix="" />
            </span>
            <p className="counter__title">Parents</p>
          </div>
        </Row>
      </div> */}
    </div>
  );
};

export default Text;
