import React from "react";
// import { Container, Row, Col } from "reactstrap";
import "../benefits.css";
// import Text from "./Text";
// import Banner from "./Banner";

const Benefits = () => {
  return (
    <div
      className="card"
      style={{ width: "100%", borderLeft: "5px solid #1f123d" }}
    >
      <div className="row">
        <div className="col-md-9">
          <div className="card-body">
            <h5 className="card-title">Benefits for Students</h5>
            <p className="card-text text-black">
              <ul>
                <li>Grades & Transcript</li>
                <li>Exam/Quiz Assessment</li>
                <li>Improves Overall Students Performances</li>
                <li>Attendance Management</li>
                <li>Course History</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="16"
              fill="currentColor"
              className="bi bi-building"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
              />
              <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
            </svg> */}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
