import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { api } from "../../http";

import { Button } from "../../components";


export default function DemoRequest() {

  useEffect(() => {
    FetchFeatures();
    FetchRoles();
    FetchSchoolTypes();
    FetchStates();
    FetchStudentsRange();
  }, []);

  const [toggledRole, setToggledRole] = useState(false);
  const [applicantRole, setApplicantRole] = useState("");
  const [referralSource, setReferralSource] = useState(false);
  const [toggledSchoolType, setToggledSchoolType] = useState(false);
  const [studentsRange, setStudentsRange] = useState({});
  const [features, setFeatures] = useState([]);
  const [roles, setRole] = useState({});
  const [schoolTypes, setSchoolTypes] = useState({});
  const [states, setStates] = useState({});
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState(false);
  const [toggledSource, setToggledSource] = useState(false);

  // Form Input Data
  const [schoolName, setSchoolName] = useState("");
  // const [schoolAddress, setSchoolAddress] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  // const [totalTeachers, setTotalTeachers] = useState("");
  const [selectedState, setSelectedState] = useState("");
  // const [ictEmail, setIctEmail] = useState("");
  // const [applicantName, setApplicantName] = useState("");
  const [schoolEmail, setSchoolEmail] = useState("");
  const [schoolPhone, setSchoolPhone] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedSchoolType, setSelectedSchoolType] = useState("");
  const [subscribe, setSubscribe] = useState(true);
  const [logo, setLogo] = useState("");
  const [interests, setInterests] = useState([]);

  async function SubmitRequest() {
    setLoader(true);
    const formData = new FormData();
    formData.append("school_logo", logo);
    formData.append("school_name", schoolName);
    // formData.append("school_address", schoolAddress);
    formData.append("state", selectedState);
    // formData.append("number_of_teachers", totalTeachers);
    // formData.append("ict_email", ictEmail);
    // formData.append("applicant_name", applicantName);
    formData.append("school_phone", schoolPhone);
    formData.append("school_email", schoolEmail);
    formData.append("applicant_role", toggledRole ? toggledRole  : applicantRole);
    formData.append("students_range", selectedRange);
    formData.append("update_notify", subscribe);
    formData.append("school_type", toggledSchoolType ? toggledSchoolType : selectedSchoolType);
    formData.append("features_interested", JSON.stringify(interests));
    formData.append("hear_about_us", toggledSource ? toggledSource : selectedSource);

    let res = await fetch(api + "/api/request/demo", {
      method: "post",
      body: formData,
    });
    res = await res.json();
    setLoader(false);
    res.errors && setErrors(res.errors);
    console.warn(res);
    !res.errors && navigate("/demo/request/sent");
  }

  function PrepareSource(value) {
    setSelectedSource(value)
    // console.log(selectedSource)
    value == "0" ? setToggledSource(true) : setToggledSource(false);
  }

  function PrepareSchoolType(value) {
    setSelectedSchoolType(value)
    value == "0" ? setToggledSchoolType(true) : setToggledSchoolType(false);
  }

  function PrepareRole(value) {
    setApplicantRole(value)
    value == "0" ? setToggledRole(true) : setToggledRole(false);
  }

  async function FetchStudentsRange() {
    let res = await fetch(api + "/api/school/packages", {
      method: "get",
    });
    res = await res.json();
    setStudentsRange(res);
  }

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  async function FetchFeatures() {
    let res = await fetch(api + "/api/features", {
      method: "get",
    });

    res = await res.json();
    res.forEach((obj) => renameKey(obj, "name", "label"));
    res.forEach((obj) => renameKey(obj, "id", "value"));

    // console.warn("res");
    setFeatures(res);
  }

  async function FetchRoles() {
    let res = await fetch(api + "/api/school/roles", {
      method: "get",
    });
    res = await res.json();
    setRole(res);
  }

  async function FetchSchoolTypes() {
    let res = await fetch(api + "/api/school/category/list", {
      method: "get",
    });
    res = await res.json();
    setSchoolTypes(res);
  }

  async function FetchStates() {
    let res = await fetch(api + "/api/nigeria/states", { method: "get" });
    res = await res.json();
    setStates(res);
  }

  const handleChange = (value) => setInterests(value);


const navigate = useNavigate();

  return (
    <div className="relative tw-flex tw-flex-col tw-justify-center tw-min-h-screen tw-overflow-hidden">
      <div className="tw-w-full tw-p-5 m-auto tw-bg-white tw-rounded-md tw-shadow-xl tw-shadow-rose-600/40 ring tw-ring-2 tw-ring-purple-600 lg:tw-max-w-xl">
        <h3 className="tw-text-2xl tw-font-semibold tw-text-center tw-text-purple-700 tw-underline tw-uppercase tw-decoration-wavy_">
          Request for Free Portal Demo
        </h3>

        {/* <form className="tw-mt-6"> */}
        <div className="mb-3">
          <label
            for="school_name"
            className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
          >
            School Name
          </label>
          <input
            onChange={(e) => setSchoolName(e.target.value)}
            type="text"
            className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
          />
          <span className="text-danger">{errors && errors.school_name}</span>
        </div>

        {/* <div className="mb-3">
          <label
            for="school_address"
            className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
          >
            School Address
          </label>
          <input
            type="text"
            onChange={(e) => setSchoolAddress(e.target.value)}
            className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
          />
          <span className="text-danger">{errors && errors.school_address}</span>
        </div> */}

        <div className="mb-3">
          <div className="row">
            <div className="col-md-6">
              <label
                for="school_state"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                State/Location of School
              </label>
              <select
                onChange={(e) => setSelectedState(e.target.value)}
                onClick={FetchStates}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              >
                <option>Select</option>
                {states &&
                  Array.from(states).map((state, key) => (
                    <option key={key} value={state.id}>{state.name}</option>
                  ))}
                ;
              </select>
            <span className="text-danger">{errors && errors.state}</span>
            </div>
            <div className="col-md-6">
              <label
                for="students_range"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                Range of Students
              </label>
              <select
                onChange={(e) => setSelectedRange(e.target.value)}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              >
                <option>Select</option>
                {studentsRange &&
                  Array.from(studentsRange).map((range, key) => (
                    <option key={key} value={range.id}>
                      {range.student_range}
                    </option>
                  ))}
              </select>
              <span className="text-danger">
                {errors && errors.students_range}
              </span>
            </div>
            {/* <div className="col-md-6">
              <label
                for="no_of_teachers"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                Number of Teachers
              </label>
              <input
                type="number"
                onChange={(e) => setTotalTeachers(e.target.value)}
                min="1"
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              />
              <span className="text-danger">{errors && errors.number_of_teachers}</span>
            </div> */}
          </div>
        </div>

        <div className="mb-3">
          <div className="row">
            {/* <div className="col-md-6">
              <label
                for="ict_email"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                ICT Admin Email
              </label>
              <input
                type="email"
                onChange={(e) => setIctEmail(e.target.value)}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
                required
              />
              <span className="text-danger">{errors && errors.ict_email}</span>
            </div> */}
          </div>
        </div>
        <div className="mb-3">
          <label
            for="school_state"
            className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
          >
            Area of Interest
          </label>
          <div className="row">
            <Select
               rules={{ required: true }}
              isMulti
              name="features"
              options={features}
              className="basic-multi-select tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              classNamePrefix="selectt"
              onChange={(selected) => {
                handleChange(selected);
              }}
              required
            />
            <span className="text-danger">{errors && errors.features_interested}</span>
            {/* {interests && <p>{JSON.stringify(interests)}</p>} */}
          </div>
        </div>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-6">
              <label
                for="school_phone"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                Tel. Number
              </label>
              <input
                type="text"
                onChange={(e) => setSchoolPhone(e.target.value)}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              />
              <span className="text-danger">{errors && errors.school_phone}</span>
            </div>

            <div className="col-md-6">
              <label
                for="school_email"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                Email
              </label>
              <input
                type="email"
                onChange={(e) => setSchoolEmail(e.target.value)}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              />
              <span className="text-danger">{errors && errors.school_email}</span>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="row">
            <div className="col-md-6">
              <label
                for="school_type"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                School Type
              </label>
              <select
                onChange={(e) => PrepareSchoolType(e.target.value)}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              >
                <option>Select</option>
                {schoolTypes &&
                  Array.from(schoolTypes).map((type, key) => (
                    <option key={key} value={type.id}>{type.name}</option>
                  ))}
                <option value="0" className="tw-font-bold">
                  Other Type
                </option>
              </select>
              <input
                type="text"
                onChange={(e) => setToggledSchoolType(e.target.value)}
                value={toggledSchoolType && toggledSchoolType == true ? '' : toggledSchoolType}
                className="_tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40 tw-hidden"
                placeholder="Write your school type"
                style={{ display: toggledSchoolType ? "block" : "none" }}
              />
              <span className="text-danger">{errors && errors.school_type}</span>
            </div>

            <div className="col-md-6">
              <label
                for="logo"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                School Logo
              </label>
              <input
                onChange={(e) => setLogo(e.target.files[0])}
                type="file"
                className="form-control mb-2"
              />
              <span className="text-danger">{errors && errors.school_logo}</span>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="row">
              <div className="col-md-6">
              <label
                for="applicant_role"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                Your Role in School
              </label>
              <select
                onChange={(e) => PrepareRole(e.target.value)}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              >
                <option>Select</option>
                {roles &&
                  Array.from(roles).map((role, key) => (
                    <option key={key} value={role.id}>
                      {role.title}
                    </option>
                  ))}
                <option value="0" className="tw-font-bold">
                  Other Role
                </option>
              </select>
              <input
                type="text"
                onChange={(e) => setToggledRole(e.target.value)}
                value={toggledRole && toggledRole == true ? '' : toggledRole}
                className="_tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40 tw-hidden"
                placeholder="Write Your Role"
                style={{ display: toggledRole ? "block" : "none" }}
              />
              <span className="text-danger">{errors && errors.applicant_role}</span>
            </div>
            <div className="col-md-6">
              <label
                for="referral_source"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                How Did You Hear About Us?
              </label>
              <select
                onChange={(e) => PrepareSource(e.target.value)}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              >
                <option>Select</option>
                <option value="Google">Google</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Twitter">Twitter</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="0" className="tw-font-bold">
                  Other Source
                </option>
              </select>
              <input
                type="text"
                onChange={(e) => setToggledSource(e.target.value)}
                className="tw-block_ tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40 tw-hidden"
                placeholder="Write where you heard about us"
                value={toggledSource && toggledSource == true ? '' : toggledSource}
                style={{ display: toggledSource ? "block" : "none" }}
              />
              <span className="text-danger">{errors && errors.hear_about_us}</span>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="row">
            {/* <div className="col-md-6">
              <label
                for="school_name"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                Your Full Name
              </label>
              <input
                type="text"
                onChange={(e) => setSchoolName(e.target.value)}
                className="tw-block tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-purple-700 tw-bg-white tw-border tw-rounded-md focus:tw-border-purple-400 focus:tw-ring-purple-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-opacity-40"
              />
              <span className="text-danger">{errors && errors.school_name}</span>
            </div> */}

            <div className="col-md-6">
              <label
                for="subscribe"
                className="tw-block tw-text-sm tw-font-semibold tw-text-gray-800"
              >
                Subscribe to Get Updates
              </label>
              <input
                onChange={(e) => setSubscribe(!subscribe)}
                type="checkbox"
                className=""
                checked={subscribe}
              />
            </div>
          </div>
        </div>

        {/* <a href="#" className="text-xs text-purple-600 hover:underline">Forget Password?</a> */}
        <div className="tw-mt-6">
          <Button
            type="submit"
            onClick={SubmitRequest}
            disabled={loader}
            className="tw-w-full tw-px-4 tw-py-2 tw-tracking-wide tw-text-white tw-transition-colors tw-duration-200 tw-transform tw-bg-purple-700 tw-rounded-md hover:tw-bg-purple-600 focus:tw-outline-none focus:tw-bg-purple-600"
          >
            {loader && <span className="spinner-grow spinner-grow-sm"></span>}
            Request
          </Button>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
}
