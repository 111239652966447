import React from "react";
import { Column, Text, Button } from "../../components";
// import  from "components/Header/Header";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";

const SupportenquirysentPage = () => {
  const navigate = useNavigate();

  // function handleNavigate() {
  //   navigate("/features");
  // }
  // function handleNavigate1() {
  //   navigate("/support");
  // }
  function handleNavigate2() {
    navigate("/support");
  }
  // function handleNavigate7() {
  //   navigate("/");
  // }

  return (
    <>
      <Layout>
        {/* <Column className="bg-white_A700 tw-font-notosans tw-items-center tw-justify-start mx-[auto] tw-w-[100%]"> */}
          <Column className="tw-flex tw-flex-col tw-items-center tw-justify-start lg:tw-mt-[25px] xl:tw-mt-[32px] 2xl:tw-mt-[36px] 3xl:tw-mt-[43px] tw-w-[100%]">
            <Column className="tw-font-opensans tw-items-center tw-justify-start tw-pt-6 tw-pb-8 tw-space-y-4">
              <Text className="heading" as="h1" variant="h1">
                Support
              </Text>
              {/* <Text className="headingdesc" variant="body13">
                We would love to hear from you
              </Text> */}
              <Text
              as="h1"
                className="lg:tw-leading-[27px] xl:tw-leading-[34px] 2xl:tw-leading-[38px] 3xl:tw-leading-[46px] tw-text-center tw-text-gray_600 w-[100%]"
                variant="body13"
              >
                Message sent succesfully. We will get back to you shortly.
              </Text>
              <Button
                className="tw-common-pointer tw-font-medium 3xl:tw-mt-[111px] lg:tw-mt-[66px] xl:tw-mt-[82px] 2xl:tw-mt-[93px] lg:tw-text-[13px] xl:tw-text-[16px] 2xl:tw-text-[18px] 3xl:tw-text-[22px] tw-text-center tw-tracking-ls1"
                onClick={handleNavigate2}
                shape="RoundedBorder6"
                size="sm"
              >
                View Contact Information
              </Button>
            </Column>
          </Column>
        {/* </Column> */}
      </Layout>
    </>
  );
};

export default SupportenquirysentPage;
