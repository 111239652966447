import React from "react";
import "./newsletter.css";
import { Container, Row, Col } from "reactstrap";
import {api} from "../../http";

import { useState } from "react";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [result, setResult] = useState([]);
  const [label, setLabel] = useState("Subscribe");
  const [status, setStatus] = useState(false);
  const [bg, setBg] = useState("pink");

  function handleKeyUp() {
    setBg('pink');
    setStatus(false);
    setResult([]);
    setLabel('Subscribe');
  }

  async function Subscribe() {
    setLabel("Subscribing");
    setStatus(true);

    const formData = new FormData();
    formData.append("email", email);

    let res = await fetch(api + "/api/subscriber/new", {
      method: "post",
      body: formData,
    });

    res = await res.json();

    setResult(res['message']);
    setStatus(res['status']);
    res['status'] == 'success' && setLabel("Subscribed");
    res['status'] == 'error' && setLabel("Subscribie");
   (email.length > 0 && res['status'] == 'success') && setBg('purple');

  }

  return (
    <section>
      <Container className="newsletter purple-bg">
        <Row>
          <Col lg="12" className="text-center">
            <h2 className="mb-4">Subscribe to Our Newsletter</h2>
              <span className="text-pink"> {status === 'error' && result} </span>
            <div className="subscribe">
              <input
                type="text"
                onKeyUp={handleKeyUp}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <button
                className={"btn " + bg + "-bg"}
                onClick={Subscribe}
                disabled={(label !== 'Subscribe') ? true : false}
              >
                {/* <i className="fa fa-refresh fa-spin"></i> */}
                {label}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Newsletter;
