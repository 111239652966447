import React from "react";
import Support from "../pages/Support/";
import Features from "../pages/Features";
import Supportenquiry from "../pages/Support/Enquiry";
import Supportenquirysent from "../pages/Support/EnquirySent";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Benefits from '../pages/Benefits';
import DemoRequest from '../pages/Demo/Request';
import DemoRequestSent from '../pages/Demo/RequestSent';

const ProjectRoutes = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/supportenquirysent" element={<Supportenquirysent />} />
        <Route path="/supportenquiry" element={<Supportenquiry />} />
        <Route path="/demo/request/sent" element={<DemoRequestSent />} />
        <Route path="/features" element={<Features />} />
        <Route path="/support" element={<Support />} />
        <Route path="/benefits" element={<Benefits />} />
        <Route path="/demo/request" element={<DemoRequest />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default ProjectRoutes;
