import React from "react";
import { Column, Text, Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";

const DemoRequestSentPage = () => {
  const navigate = useNavigate();

  function homeNavigate() {
    navigate("/");
  }

  return (
    <>
      <Layout>
        {/* <Column className="bg-white_A700 tw-font-notosans tw-items-center tw-justify-start mx-[auto] tw-w-[100%]"> */}
          <Column className="tw-text-center tw-justify-start lg:tw-mt-[25px] xl:tw-mt-[32px] 2xl:tw-mt-[36px] 3xl:tw-mt-[43px] tw-w-[100%]">
            <Column className="tw-font-opensans tw-items-center tw-justify-start tw-pt-6 tw-pb-8 tw-space-y-4">
              <Text className="heading" as="h1" variant="h4">
                Your request for free demo has been submitted successfully
              </Text>
              <Text className="headingdesc" variant="body1">
                We will keep in touch with you in a moment
              </Text>
              <br /><br />
              <Button
                className="tw-common-pointer tw-font-medium 3xl:tw-mt-[111px] lg:tw-mt-[66px] xl:tw-mt-[82px] 2xl:tw-mt-[93px] lg:tw-text-[13px] xl:tw-text-[16px] 2xl:tw-text-[18px] 3xl:tw-text-[22px] tw-text-center tw-tracking-ls1"
                onClick={homeNavigate}
                shape="RoundedBorder6"
                size="md"
              >
                Return to Homepage
              </Button>
              <Text
                className="lg:tw-leading-[27px] xl:tw-leading-[34px] 2xl:tw-leading-[38px] 3xl:tw-leading-[46px] tw-text-center tw-text-gray_600 w-[100%]"
                variant="body23"
              >
              </Text>
            </Column>
          </Column>
        {/* </Column> */}
      </Layout>
    </>
  );
};

export default DemoRequestSentPage;
