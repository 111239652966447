import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../components/Feature-Section/features.css";
import FeatureCard from "../components/Header/FeatureCard";

const FeatureData = [
  {
    title: "Assignment/Digital Lesson",
    desc: "Assignments can be created with the help of a rich-text editor tool or an assignment file can be uploaded having the assignment. Students can view the list of available assignments for them & can view or download the assignments",
    icon: "ri-draft-line",
  },
  {
    title: "Computer Based Test (CBT)",
    desc: "Computer Based Test (CBT)",
    icon: "ri-discuss-line",
  },
  {
    title: "Student Performance Analysis",
    desc: "Student Performance Analysis",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Online Enrollment",
    desc: "Online Enrollment",
    icon: "ri-contacts-book-line",
  },
  {
    title: "BIO Data and Admission",
    desc: "BIO Data and Admission",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Time Table Management",
    desc: "Time tables can be added for classes & teachers.",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Student Attendant Management",
    desc: "Students can view their attendance; parents can also watch their children’s attendance from home. Class-teachers / data-entry operators can add-students (class-wise) attendance. While taking the attendance the students leave application is visible if any leave is filed online by the student.",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Electronic School fees Payment System",
    desc: "School fees details will be available in this section. Fees can be easily paid online using credit/debit cards or e-checks through a secured & encrypted payment gateway. (online payment facility available in pro version)",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Student Performance View",
    desc: "Student Performance View",
    icon: "ri-contacts-book-line",
  },
  {
    title: "PDF Compatible Report Card",
    desc: "This section shows all the results/marks/performance of students. Students can view their marks/report-card in this section. Admin/Parents can watch students/Childs performance.",
    icon: "ri-contacts-book-line",
  },
  {
    title: "E-library",
    desc: "Brainpiece E-Library System streamlines and simplifies E-Library management. It integrates all Library administration tasks into one user-friendly database designed to meet all of your Institution’s needs \n It eliminates the need for book keeping pitfalls and data loss due to overly complicated backup procedures.",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Integrated Bulk SMS",
    desc: "Integrated bulk SMS and e-mail facility.",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Integrated Email Platform",
    desc: "Integrated Email Platform",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Remote Access",
    desc: "Remote Access",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Free Website",
    desc: "Free Website",
    icon: "ri-contacts-book-line",
  },
];

const Features = () => {
  return (
    <section>
      <Container>
        <Row>
            <h2 className="mb-5 text-center">Features of Brainpiece</h2>
          {FeatureData &&
            FeatureData.map((item, index) => (
              <Col lg="4" md="6" className="mb-3" key={index}>
                <FeatureCard feature={item} />
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;
