import React, { useRef } from "react";
import { Container } from "reactstrap";
import logo from '../../assets/images/logo.png';
import "./header.css";
import { Link } from 'react-router-dom'

const navLinks = [
  {
    display: "Home",
    url: "/",
  },
  {
    display: "Benefits",
    url: "benefits",
  },
  {
    display: "Features",
    url: "features",
  },
  {
    display: "Support",
    url: "support",
  },
  {
    display: "Request Demo",
    url: "demo/request",
  },
];

const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active_menu");

  return (
    <header className="header">
      <Container>
        <div className="navigation d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/" className="d-flex align-items-center" style={{ textDecoration: 'none' }}>
              <img src={logo} alt="brainpiece logo"/>
            </Link>
          </div>

          <div className="nav d-flex align-items-center gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list">
                {navLinks.map((navlink, index) => (
                  <li key={index} className="nav__item">
                    <Link to={navlink.url}>{navlink.display}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="nav__right">
              <p className="mb-0 d-flex align-items-center gap-3">
                <i className="ri-phone-line"></i> +234-812-4619-885
              </p>
            </div>

            <div className="mobile__menu">
              <span>
                <i className="ri-menu-line" onClick={menuToggle}></i>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
