import React from "react";

import { Column } from "../";
// import { Column, Header, Footer } from "../";
// import { useNavigate } from "react-router-dom";
// import {  } from "../../pages/Home1/Footer";
// import { Header } from "../../pages/Home1/Header";

export function Layout(props) {
  // const navigate = useNavigate();

  // function handleNavigate14() {
  //   navigate("/features");
  // }
  // function handleNavigate15() {
  //   navigate("/support");
  // }
  // function handleNavigate16() {
  //   navigate("/supportenquiry");
  // }

  return (
    <>
      <Column className="bg-white_A700 font-poppins items-center justify-start mx-[auto] w-[100%]">
        {/* <Header /> */}
        <div className="tw-my-6 lg:tw-my-8">{props.children}</div>
        {/* <Footer /> */}
      </Column>
    </>
  );
}
