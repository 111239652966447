import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./hero-section.css";
import Text from "../Hero-Section/Text";
import Banner from "../Hero-Section/Banner";

const HeroSection = () => {
  return (
    <section>
      {/* <Row>Femi why</Row> */}
      <Container>
        <Row>
           <Col lg="6">
            <div className="d-none d-lg-block">
              <Text />
            </div>
            <div className="d-lg-none">
              <Banner />
            </div>
          </Col>

           <Col lg="6">
            <div className="d-lg-none">
              <Text />
            </div>
            <div className="d-none d-lg-block">
              <Banner />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
