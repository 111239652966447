// import axios from 'axios';
// import React from 'react';

// export const api = () => {

  let api;
  switch (process.env.NODE_ENV) {
    case "production":
      api = "https://demo.brainpiece.net";
      break;
    case "development":
        api = "http://localhost:8000";
    default:
      api = "http://localhost:8000";
  }
  // return url;
// };

// export {api};

export {api}

// export default axios.create({
//     baseURL: getBaseUrl(),
// })